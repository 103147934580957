#contact {
    width: 100%;
    padding: 4em 2rem;
    overflow-x: hidden; /* Ensure no horizontal scroll */
  }

  .app__footer-cards {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin: 4em 2rem 2rem;
    max-width: 100%; /* Ensure it does not exceed the viewport width */
    overflow-x: hidden;
}

* {
    box-sizing: border-box;
  }


  .app__footer-card {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    background-color: rgba(79, 211, 200, 0.5); /* Light blue background */
    transition: all 0.3s ease-in-out;
    margin: 1rem 0;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 25px rgba(79, 211, 200, 0.5); /* Light blue shadow */
    }

    img {
      width: 35px;
      height: 35px;
      margin-right: 0.7rem;
    }

    a {
      text-decoration: none;
      font-weight: 500;
      color: #ffffff; /* Adjusted for better contrast */
    }
  }

  .app__footer-form {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: auto;
  }

  /* Style for input fields */
.app__footer-form .p-text {
    padding: 0.95rem;
    margin: 0.75rem 0;
    border-radius: 7px;
    border: 1px solid #ccc; /* Slightly defined border */
    background-color: #f0f0f0; /* Light grey for inputs */
    color: #333; /* Darker text for contrast */
    outline: none;
    width: 100%; /* Ensures input fields take up the full width of the form */
  }
  button {
    background-color: #30b950;
  }

  /* Style for textarea */
  .app__footer-form textarea {
    padding: 0.95rem;
    border-radius: 7px;
    border: 1px solid #ccc;
    background-color: #f0f0f0; /* Matching the input fields for consistency */
    color: #333;
    outline: none;
    width: 100%;
    height: 170px; /* Adjust height as needed */
  }

  /* Specific style for the "Send Message" button */
  .footer-send-btn {
    padding: 1rem 2rem;
    max-width: 200px; /* Controls the button size */
    width: auto; /* Adjusts based on content size */
    border-radius: 10px;
    border: none;
    background-color: #32cd32; /* Vibrant green for the button */
    color: #837d7d; /* White text for readability */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: block;
    margin: 2rem auto; /* Centers the button */

    &:hover {
      background-color: #28a745; /* Darker green on hover */
      color: #fff; /* Keeps text color consistent on hover */
    }
  }

  @media screen and (max-width: 768px) {
    .footer-send-btn {
      max-width: 100%; /* Full width on smaller screens */
      width: 100%;
    }
  }
/* Footer Copyright Styles */
footer .footer-copyright {
    text-align: center;
    padding: 1rem 0;
    font-size: 0.875rem; /* Adjust based on your design */
    color: #666; /* Subtle text color, adjust as needed */
    margin-top: 2rem; /* Adds space between the copyright notice and the rest of the footer content */
    font-family: 'Times New Roman', Times, serif;
    font-size: smaller;
  }
  @media screen and (max-width: 768px) {
    .footer-send-btn {
      max-width: 100%; /* Ensures no overflow on small screens */
      width: 100%;
    }
  }
