/* About.css */

/* Default styles */
.about-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-top: 50px;
  }

  .slogan-container {
    text-align: center;
    margin-bottom: 20px;
  }

  .slogan {
    text-align: center;
  }

  .slogan-primary {
    color: #2e86bd;
  }

  .slogan-secondary {
    color: #f0512e;
  }

  .new-sections-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
  }

  .new-section-container {
    width: 80%;
    margin: 1rem 0;
  }

  .section-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  .section-content {
    padding: 1rem;
  }

  .section-title {
    font-size: 1.5em;
    margin-bottom: 0.5rem;
    width: 100%;
  }

  .section-description {
    font-size: 1em;
  }

  /* Media queries */
  @media screen and (max-width: 577px) {
    .new-section-container {
      align-items: center;
    }
  }

  @media screen and (min-width: 578px) and (max-width: 1199px) {
    .new-section-container {
      width: 70%;
    }
  }

 /* Adjustments for screens between 598px and 1200px */
@media screen and (min-width: 598px) and (max-width: 1200px) {
    .new-sections-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      align-items: center; /* Center align the grid items */
      height: auto;
      width: 100%;
    }

    .new-section-container {
      width: 40%;
      margin: 1rem 0;
      margin-top: 140px;
      justify-self: center; /* Center align the new-section-container inside the grid item */
    }
  }

  @media screen and (min-width: 1200px) {
    .new-sections-container {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1.5rem;
      align-items: center;
      height: auto;
    }

    .about-container {
      margin-top: -61px;
    }

    .new-section-container {
      width: 90%;
      margin: 1rem 0;
    }
  }

  @media screen and (min-width: 1600px) {
    .new-section-container {
      width: 100%;
    }

    .about-container {
      margin-top: -25vh;
    }
  }

  @media screen and (min-width: 768px) {
    .new-section-container .section-content {
      padding: 0 20px;
      margin: 10px 0;
    }
  }

  @media screen and (min-width: 1200px) {
    .about-container {
      align-items: center;
    }

    .new-section-container .section-content {
      margin-left: 20px;
    }

    .new-sections-container {
      width: 65%;
    }

    .new-section-container .section-content {
      padding: 0 40px;
      margin: 20px 0;
    }
  }

  @media screen and (min-width: 1600px) and (max-width: 2400px) {
    .new-section-container .section-content {
      padding: 0 80px;
      margin: 30px 0;
    }
  }

  @media screen and (min-width: 2400px) {
    .about-container {
      align-items: center;
    }

    .new-sections-container {
      width: 80%;
    }

    .new-section-container .section-content {
      padding: 0 100px;
      margin: 40px 0;
    }
  }
