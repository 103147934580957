.work-container {
    margin-top: -110px; /* Default margin for large screens */
  }

  @media screen and (min-width: 1201px) {
    .work-container {
      margin-top: -30px; /* Adjust the margin for screens 1201px and above */
    }
  }

  @media screen and (min-width: 599px) and (max-width: 1200px) {
    .work-container {
      margin-top: 270px; /* Adjust the margin for screens between 599px and 1200px */
    }
  }
/* Adjust the margin for screens between 1201px and 2400px */
@media screen and (min-width: 1201px) and (max-width: 2400px) {
    .work-container {
      margin-top: -180px;
    }
  }

  @media screen and (max-width: 598px) {
    .work-container {
      margin-top: 490px; /* Adjust the margin for screens below 598px */
    }
  }

  @media screen and (max-width: 768px) {
    .work-container {
      margin-top: 690px; /* Adjust the margin for medium-sized screens */
    }
  }


.app__works {
    flex: 1;
    width: 100%;
    flex-direction: column;


  }

  .head-text, .app__work-filter {
    text-align: center;
  }

  @media screen and (max-width: 767px) {
    .head-text, .app__work-filter {
      padding-top: 1.5rem; /* Adjust the padding as needed */
    }
  }

  .app__work-filter {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Center the filter horizontally */
    align-items: center;
    flex-wrap: wrap;
    margin: 4rem 0 2rem;
  }

  .app__work-filter-item {
    padding: 0.5rem 1rem;
    border-radius: 0.5rem;
    background-color: #090d47;
    color: #f3eeee;
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 0.5rem;
  }

  .app__work-filter-item:hover {
    background-color: grey;
    color: #140202;
    border-radius: 0.5rem; /* Add rounded edges on hover */
  }

  /* New styles for the app__work-filter-item when hovered */
  .app__work-filter-item:hover .p-text {
    background-color: purple; /* Change to your desired background color on p-text hover */
    color: #fff; /* Change to your desired text color on p-text hover */
    border-radius: 0.5rem; /* Add rounded edges on p-text hover */
  }

  @media screen and (min-width: 2000px) {
    .app__work-filter-item {
      padding: 1rem 2rem;
      border-radius: 0.85rem;
    }
  }

  .item-active {
    background-color: var(--secondary-color);
    color: #140202;
  }

  .app__work-portfolio {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .app__work-item {
    width: 270px;
    flex-direction: column;
    margin: 2rem;
    padding: 1rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .app__work-item:hover {
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.4);
  }

  @media screen and (min-width: 2000px) {
    .app__work-item {
      width: 470px;
      padding: 1.25rem;
      border-radius: 0.75rem;
    }
  }

  @media screen and (max-width: 300px) {
    .app__work-item {
      width: 100%;
      margin: 1rem;
    }
  }

  .app__work-img {
    width: 100%;
    height: 230px;
    position: relative;
  }

  .app__work-img img {
    width: 100%;
    height: 100%;
    border-radius: 0.5rem;
    object-fit: cover;
  }

  @media screen and (min-width: 2000px) {
    .app__work-img {
      height: 350px;
    }
  }

  .app__work-hover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
    opacity: 0;
    transition: all 0.3s ease;
  }

  .app__work-hover div {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    margin: 1rem;
    font-family: var(--font-base);
    font-weight: 800;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .app__work-hover div svg {
    width: 50%;
    height: 50%;
    margin-left: 12px;
    margin-top: 11px;
    color: var(--white-color);
  }

  .app__work-content {
    padding: 0.5rem;
    width: 100%;
    position: relative;
    flex-direction: column;
  }

  .app__work-content h4 {
    margin-top: 1rem;
    line-height: 1.5;
  }

  @media screen and (min-width: 2000px) {
    .app__work-content h4 {
      margin-top: 3rem;
    }
  }

  .app__work-tag {
    position: absolute;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    margin-left: 90px;
    background-color: #fff;
    top: -25px;
  }
