.app__skills {
    flex: 1;
    width: 100%;
    flex-direction: column;
  }

  .app__skills-container {
    width: 80%;
    margin-top: 3rem;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
      width: 100%;
      flex-direction: column;
    }
  }

  .app__skills-list {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 5rem;

    @media screen and (max-width: 900px) {
      margin-right: 0;
      justify-content: center;
      align-items: center;
    }
  }

  .app__skills-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 1rem;
    transition: all 0.3s ease-in-out;
  }

  .app__skills-item div {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #fef4f5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .app__skills-item div img {
    width: 50%;
    height: 50%;
  }

  .app__skills-item div:hover {
    box-shadow: 0 0 25px #f5eff0;
  }

  @media screen and (min-width: 2000px) {
    .app__skills-item div {
      width: 150px;
      height: 150px;
    }

    .app__skills-item {
      margin: 1rem 2rem;
    }

    .app__skills-item p {
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 450px) {
    .app__skills-item div {
      width: 70px;
      height: 70px;
    }
  }

  .app__skills-exp {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

  }
/* Add a media query for smaller screens */
@media screen and (max-width: 768px) { /* Adjust this breakpoint as needed */
    .app__skills-exp {
      padding-top: 40px; /* Adjust the padding value as needed */
      width: 66%;
      padding-left: 20px;
    }
  }
  .app__skills-exp-item {
    display: flex;
    justify-content: flex-start; /* Aligns items to the start (left) */
    align-items: flex-start; /* Ensures top alignment */
    gap: 20px; /* Space between columns */
    width: 100%;
  }


.app__skills-exp-year {
    order: 2; /* Ensures year comes after logo */
    flex-basis: 20%;
    margin-right: 0; /* Adjust according to new layout */
  }

  .app__skills-exp-year p {
    font-weight: 800;
    color: var(--secondary-color);
  }

  @media screen and (max-width: 450px) {
    .app__skills-exp-year {
      margin-right: 1rem;
    }
  }

  .app__skills-exp-work {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }

  .work-text {
    flex: 1;
    text-align: left; /* Aligns text to the left */
    padding-right: 20px; /* Keeps some space between text and the logo */
    margin-top: -22px;
    padding-bottom: 20px;
  }
  .work-logo {
    order: 1; /* Ensures logo is first */
    flex-basis: 20%;
  }


  .work-logo img {
    width: 80px;
    height: auto;
    object-fit: cover;
  }
  .app__skills-exp-works {
    order: 3; /* Ensures works details come last */
    flex-grow: 2;
  }

  .skills-tooltip {
    max-width: 300px !important;
    background-color: var(--white-color) !important;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.1) !important;
    border-radius: 5px !important;
    padding: 1rem !important;
    color: var(--gray-color) !important;
    text-align: center !important;
    line-height: 1.5 !important;
    opacity: 1 !important;

    @media screen and (min-width: 2000px) {
      font-size: 1.75rem !important;
      max-width: 500px !important;
      line-height: 2 !important;
    }
  }
